import React, { ChangeEvent, useEffect, useState } from "react";
import {
  useFormControl,
  ReactFormInputOptions,
  useInjector,
} from "@vendure/admin-ui/react";

import { useMutation } from "@vendure/admin-ui/react";
import { gql } from "graphql-tag";

const CREATE_STAGED_UPLOAD = gql`
  mutation createStagedUpload($input: CreateStagedUploadInput!) {
    createStagedUpload(input: $input) {
      url
      signedUrl
      id
    }
  }
`;

function getImageData(event: ChangeEvent<HTMLInputElement>) {
  // FileList is immutable, so we need to create a new one
  const dataTransfer = new DataTransfer();

  // Add newly uploaded images
  Array.from((event.target.files || []) as File[]).forEach((image) =>
    dataTransfer.items.add(image)
  );

  const files = dataTransfer.files;
  const displayUrl = URL.createObjectURL((event.target.files || [])[0]);

  return { files, displayUrl };
}

export function ImageUpload({ value, onChange }) {
  const [createStagedUpload, { data, loading, error }] =
    useMutation<any>(CREATE_STAGED_UPLOAD);
  // const translateService = useInjector("TranslateService");
  const [preview, setPreview] = useState(value || "");
  const [file, setFile] = useState<File | null>(null);
  const [progress, setProgress] = useState(0);
  const [isStarted, setIsStarted] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadUrl, setUploadUrl] = useState<string>();
  const [isCompleted, setIsCompleted] = useState(false);
  const [uploadedUrl, setUploadedUrl] = useState<string>();

  useEffect(() => {
    if (
      data?.createStagedUpload?.signedUrl &&
      file &&
      isStarted &&
      !isCompleted &&
      !isUploading
    ) {
      setIsUploading(true);
      setUploadUrl(data.createStagedUpload.signedUrl);
      const upload = doUpload({
        file,
        url: data.createStagedUpload.signedUrl,
        onSuccess: (result) => {
          setIsCompleted(true);
          setIsUploading(false);
          setUploadedUrl(data.createStagedUpload.url);
          onChange(data.createStagedUpload.url);
        },
      });
      return () => {
        //       // if ()
        //       // upload?.abort();
      };
    }
  }, [data, onChange, isStarted]);

  function startUpload(file: File) {
    setFile(file);
    setIsStarted(true);
    const res = createStagedUpload({
      input: {
        name: file.name,
        mimetype: file.type,
      },
    });
  }
  const doUpload = ({
    url,
    onProgress,
    onError,
    onSuccess,
    file,
  }: {
    url: string;
    onProgress?: (percent: number, event: ProgressEvent) => void;
    onError?: (e: any) => void;
    onSuccess: (response: { url: string }) => void;
    file: File;
  }) => {
    // const data = null;
    //  await createUpload({
    //   name: file.name,
    //   fileType: file.type,
    // });
    // if (!data) return;
    const signedUrl = url;
    const xhr = new XMLHttpRequest();
    xhr.open("PUT", new URL(signedUrl));

    if (xhr.upload) {
      xhr.upload.onprogress = function (event) {
        let percent = 0;

        if (event.total > 0) {
          percent = (event.loaded / event.total) * 100;
        }

        onProgress?.(percent, event);
      };
    }

    xhr.onerror = function error(e) {
      console.log("error", e);
      alert("An error occurred while uploading");
      onError?.(e);
    };

    xhr.onload = async function onload() {
      if (xhr.status < 200 || xhr.status >= 300) {
        alert("upload error");
        alert(xhr.responseText);
        return onError?.(xhr.response);
      }
      // await completeUpload({
      //   id: data.id,
      // });

      onSuccess({
        url,
        ...xhr.response,
      });
    };
    xhr.send(file);
    return {
      abort() {
        xhr.abort();
      },
    };
  };

  return (
    <div>
      <div>
        {/* <pre className="overflow-auto w-80">
          {JSON.stringify(data, null, 2)}
        </pre> */}
        {/* <pre className="overflow-auto w-80">
          {JSON.stringify(
            {
              progress,
              isStarted,
              isUploading,
              uploadedUrl,
              uploadUrl,
              isCompleted,
            },
            null,
            2
          )}
        </pre> */}
      </div>

      <label
        htmlFor="file"
        className="block text-sm font-medium text-gray-700 cursor-pointer"
      >
        <span className="text-primary hover:text-primary-light">
          {/* {t('checkout.uploadPhoto')} */}
          {preview || value ? (
            <img
              className="object-scale-down max-h-full drop-shadow-md rounded-md m-auto"
              src={preview || value}
              alt="uploaded"
            />
          ) : (
            "Select an image to upload"
          )}
        </span>
      </label>
      <input
        id="file"
        type="file"
        accept="image/*;capture=camera"
        // {...rest}
        // style={{ display: "none" }}
        onChange={(event) => {
          const { files, displayUrl } = getImageData(event);
          setPreview(displayUrl);
          setProgress(5);
          startUpload(files[0]);
        }}
      />
    </div>
  );
}

export function ImageUploadFormInput(props: ReactFormInputOptions) {
  const { value, setFormValue } = useFormControl();

  const handleChange = (newValue: string) => {
    setFormValue(newValue);
  };
  return (
    <>
      {/* <div>Image Upload</div> */}
      <ImageUpload onChange={handleChange} value={value} />
      {/* {value} */}
    </>
  );
}
