import { addActionBarItem, addNavMenuItem, addNavMenuSection } from '@vendure/admin-ui/core';
import { registerReactFormInputComponent } from '@vendure/admin-ui/react';

import { ImageUploadFormInput } from './components/image-upload-form-input';

export default [
    registerReactFormInputComponent('image-upload-form-input', ImageUploadFormInput),
    // addActionBarItem({
    //     id: 'test-button',
    //     label: 'Test Button',
    //     locationId: 'order-list',
    // }),
    // addNavMenuSection(
    //     {
    //         id: 'greeter',
    //         label: 'My Extensions',
    //         items: [
    //             {
    //                 id: 'greeter',
    //                 label: 'Greeter',
    //                 routerLink: ['/test'],
    //                 // Icon can be any of https://core.clarity.design/foundation/icons/shapes/
    //                 icon: 'cursor-hand-open',
    //             },
    //         ],
    //     },
    //     // Add this section before the "settings" section
    //     'settings',
    // ),
    // addNavMenuItem(
    //     {
    //         id: 'collections', // <-- we will override the "collections" menu item
    //         label: 'Collections',
    //         routerLink: ['/catalog', 'collections'],
    //         // we use an invalid permission which ensures it is hidden from all users
    //         requiresPermission: '__disable__',
    //     },
    //     'catalog',
    // ),
];
